import '../../styles/homeandlifepage.css';

import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Illustration from '../../images/illustration-bubble-tip.png';
import girlwithcat from '../../images/images/group-3.png';
import PH_Image from '../../images/images/group-8.png';
import righttick from '../../images/images/icon-tick-green.svg';
import bitmap_1 from '../../images/images/ratingOne.png';
import bitmap_3 from '../../images/images/ratingThree.png';
import bitmap_2 from '../../images/images/ratingTwo.png';
import nationwidelogo from '../../images/nationwidelogo.png';

const NationwideHomePage = ( { data, parent } ) => {

  return (
    <Layout header={'menu'}>
      <SEO
        title="Homeowners insurance by Nationwide, Nationwide, Best Life Insurance Policy, Life insurance quotes online, Online insurance"
        canonicalUrl='https://www.getmybubble.com/nationwidehome/'
        description="Homeowners insurance by Nationwide, Nationwide, Homeowners insurance quotes online, Online  home insurance policy"
        keywords="Homeowners insurance by Nationwide, Nationwide, Homeowners insurance quotes online, Online  home insurance policy"
      />

      {/* <Menu /> */}

      <div className="flexColumn carrierCommonPage">
        <div className="container-fluid carrier-plan-container_1">
          {/* carrier-plan-container */}

          <div className="container">
            <div className="row">
              <div className="col-lg-6 ">
                <div className="padding-left">
                  <img src={nationwidelogo} alt="logo" width="200"/>

                  <h1 className="carrier-plan-heading">Homeowners insurance</h1>

                  <p className="carrier-plan-section1-para">
                  Nationwide's Homeowners insurance can protect your home against theft, fire, lawsuits, weather damage and other covered losses.
                  </p>

                  <div className="flexRow align-btns ml-1">
                    <Link to="/">
                      <button type="button" className="orangeBtn mr-3">
                      Get a quote now
                      </button>
                    </Link>

                    <a href="#start-exploring" style={{ color: '#ff7007', textDecoration: 'none' }}>
                      <button type="button" className="explore">
                        Explore
                      </button>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 sbli-image" style={{ textAlign: 'center' }}>
                <img src="https://devuiassetscdn.getmybubble.io/images/group-7-house.png" className="carrier-right-img-mv mt-4 mt-sm-4 mt-md-4 mt-lg-0" alt="" />
              </div>
            </div>
          </div>

        </div>

        {/*section 2*/}

        <div id="start-exploring" className="container-fluid" style={{ marginTop: '80px' }}>
          <div className="row">
            <div className="col-lg-3"></div>

            <div className="col-lg-6 costom-padding19">
              <h3 className="stillwater-title1">
              Ways to save on your homeowners insurance
              </h3>

              <p className="stillwater-para1">
              Nationwide offers a range of home insurance policies to cover you in the event of theft, water backup, personal injury and other types of losses. They not only cover your home, but also you, your family and your personal belongings.
              </p>

            </div>

            <div className="col-lg-3"></div>
          </div>
        </div>

        {/* Product Highlights Section */}

        <div className="container-fluid">
          <div className="row" style={{ marginTop: '30px' }}>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-2"></div>

                <div className="col-lg-9 product-container-alignment costom-padding19">
                  <div className="stillwater-product-container">

                    <div className="align-horizontal-center">
                      <img src={PH_Image} className="ph-desktop-view" alt="phdesk"/>

                      <h3
                        className="ph-title ph-desktop-view">
                        Nationwide's Homeowner Discounts
                      </h3>

                      <h3
                        className="ph-title ph-mobile-view">
                        Nationwide's Homeowner Discounts
                      </h3>

                      <img src={PH_Image} className="ph-mobile-view" alt="phmob"/>
                    </div>

                    <div
                      style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} className="img-responsive" alt="tick" style={{ verticalAlign: 'middle' }}></img>

                      <div className="ph-item-style pl-3">
                        Multi-policy
                        Combine and save. Get a discount on home and renters insurance when you bundle your property and auto insurance with Nationwide.
                      </div>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} alt="tick"/>

                      <div className="ph-item-style pl-3">
                        Protective device
                        Get a discount if your home has smoke detectors, fire alarms, burglar alarms or other qualifying devices.
                      </div>

                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} alt="tick"/>

                      <div className="ph-item-style pl-3">
                        Claims-free
                        You may qualify for a lower premium on your home insurance when you stay claims-free.
                      </div></div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} alt="tick"/>

                      <div className="ph-item-style pl-3">
                        Home renovation credit
                        Get a discount for renovating your home’s plumbing, heating, cooling or electrical systems.
                      </div></div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} alt="tick"/>

                      <div className="ph-item-style pl-3">
                        Roof rating
                        Save based on your roof's age and surface type.
                      </div></div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} alt="tick"/>

                      <div className="ph-item-style pl-3">
                        Home purchase
                        Receive a discount if you purchased a home within the last 12 months.
                      </div></div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} alt="tick"/>

                      <div className="ph-item-style pl-3">
                        Prior insurance
                        This discount is available to new members and is based on the number of years you were continuously insured by your previous carrier.
                      </div></div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} alt="tick"/>

                      <div className="ph-item-style pl-3">
                        Gated community
                        A discount is available when all vehicle entrances to your community are protected at all times by
                      </div></div>

                  </div>

                </div>

                <div className="col-lg-1"></div>
              </div>
            </div>

            <div className="col-lg-6 carrier-middle-container carrier-middle-container-mv">
              <h4 className="stillwater-col2-title">Standard Coverages:</h4>

              <ul className="stillwater-list stillwater-list-item stillwater-list-mv common-width">
                <li>
                  <b>Dwelling Coverage</b>

                  <p>Helps protect your home's structure, built-in appliances and wall-to-wall carpet from damage.</p>
                </li>

                <li>
                  <b>Contents</b>

                  <p>Helps cover your personal property not attached to or built into your home, such as furniture and clothing.</p>
                </li>

                <li>
                  <b>Personal liability</b>

                  <p>If an accident occurs in your home that damages someone or something, personal liability can help pay for covered damages.</p>
                </li>

                <li>
                  <b>Medical payments to others</b>

                  <p>May help pay for medical or funeral expenses of someone who is injured on your property.</p>
                </li>

                <li>
                  <b>Ordinance or law insurance</b>

                  <p>May help pay to rebuild your home to current building codes after a covered loss.</p>
                </li>

                <li>
                  <b>Other structures</b>

                  <p>Covers additional structures not attached to your house, such as a shed, fence, barn, detached garage or gazebo.</p>
                </li>

                <li>
                  <b>Loss of use</b>

                  <p>Coverage to help pay for additional living expenses if you're unable to live in your home due to a covered loss.</p>
                </li>

                <li>
                  <b>Credit card coverage</b>

                  <p>Pays up to your selected limit for unauthorized transactions on your credit cards and bank debit/ATM card. Also applies to forged checks and counterfeit money.</p>
                </li>

              </ul>

              <h4 className="stillwater-col2-title">Optional Coverages:</h4>

              <ul className="stillwater-list stillwater-list-item stillwater-list-mv common-width">
                <li>
                  <b>Identity theft</b>

                  <p>Helps keep personal data secure amid a growing number of risks and cybercrimes.</p>
                </li>

                <li>
                  <b>Valuables Plus</b>

                  <p>Provides additional coverage for high-value items such as jewelry, watches, antiques and fine art.</p>
                </li>

                <li>
                  <b>Dwelling Replacement Cost</b>

                  <p>Can pay up to two times your dwelling coverage limit if your home needs to be rebuilt after a loss.</p>
                </li>

                <li>
                  <b>Water backup</b>

                  <p>Provides coverage if water backs up through your sewer, drains or your sump pump overflows.</p>
                </li>

                <li>
                  <b>Earthquake</b>

                  <p>Provides coverage for earthquake damage. A separate deductible applies.</p>
                </li>

                <li>
                  <b>Flood insurance</b>

                  <p>This option protects your house and its contents from flood damages. It is a separate policy with its own deductible.</p>
                </li>


              </ul>


            </div>
          </div>
        </div>

        <div className="container stillwater-card-container">
          <div className="row">
            <div className="col-lg-2">
            </div>

            <div className="col-lg-8">
              <h3 className="stillwater-title1">When it comes to protecting your assets, we make the grade.</h3>

              <div className="card-deck align-stillwater-cards">

                <div className="row">
                  <div className="col-md-4 col-lg-4 section30 costom-padding19">
                    <div className="card stillwater-cards">
                      <div className="card-header align-stillwater-card-header">
                        <img src={bitmap_1} alt="bitmap" className="image-grades"/>
                      </div>

                      <div className="card-body bg-card-body">
                        <p className="stillwater-card-text"> received 10/17/2002<br/>
                          affirmed 12/22/21</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-4 section30 costom-padding19">
                    <div className="card stillwater-cards">
                      <div className="card-header align-stillwater-card-header">
                        <img src={bitmap_2} alt="bitmap" className="image-grades"/>
                      </div>

                      <div className="card-body bg-card-body">
                        <p className="stillwater-card-text">received 12/22/2008
                          <br/>affirmed 5/7/21</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-4 section30 costom-padding19">
                    <div className="card stillwater-cards">
                      <div className="card-header align-stillwater-card-header">
                        <img src={bitmap_3} alt="bitmap" className="image-grades"/>
                      </div>

                      <div className="card-body bg-card-body">
                        <p className="stillwater-card-text">
                        received 3/10/2009
                          <br/>affirmed 5/27/2020
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-2">
          </div>
        </div>

        <div className="plansForYou">
          <div className="orangeBackGround">
            <div>
              Find out which plan is right for you
            </div>

            <div className="customizedPlan">
              Policies are customized according to your needs.
              We have simplified the process to help you find the right policy that fits your budget and loved ones.
            </div>

            <Link to="/" type="button" className="btnWhite">
              Get your quote now
            </Link>
          </div>
        </div>
      </div>
    </Layout> );
};

export default NationwideHomePage;
